import { gql } from '@apollo/client';

const OPPORTUNITIES_CONTENT = gql`
  query opportunitiesContent {
    opportunities {
      opportunity_name
      opportunity_description
      id
      payment_type
      diy
      diy_provider_url
      image {
        url
      }
    }
    scores {
      difficulty
      eco_planning
      id
      land_retention
      main_description
      timescale
      value
    }
  }
`;

export const PAYMENT_TYPE_DESCRIPTION = gql`
  query paymentTypeDescription {
    payment_type_descriptions {
      id
      total
      deposit
      per_annum
      #      per_annum_i
      per_month
      #      per_annum_ii
      #      per_annum_iii
      #      per_annum_iv
    }
  }
`;

export { OPPORTUNITIES_CONTENT };
