import { FC, useEffect, useMemo, useState } from 'react';
import './index.scss';
import ClipLoader from 'react-spinners/ClipLoader';
import { noOpportunity } from 'lib/apis';
import { getPolygon } from 'lib/polygon';

interface Props {}

const EmptyOpportunity: FC<Props> = () => {
  const [email, setEmail] = useState<string>('');
  const [contactButtonEnabled, setContactButtonEnabled] =
    useState<boolean>(false);

  const [showMessageSent, setShowMessageSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setContactButtonEnabled(email != '');
  }, [email]);

  const polygon = useMemo(() => getPolygon(), []);

  const sendContactMessage = async (): Promise<void> => {
    setContactButtonEnabled(false);
    setLoading(true);

    await noOpportunity({
      acres: Number(localStorage.getItem('acres')),
      polygon: JSON.stringify(polygon),
      email,
      screenshot: localStorage.getItem('screenshot') || ''
    });

    setContactButtonEnabled(true);
    setLoading(false);
    setShowMessageSent(true);
  };

  const renderContactform = () => {
    return (
      <div>
        <p>Sign up with your email and we will keep you updated.</p>
        <form className="no-opportunities-sign-up">
          <div className="form-cell">
            <input
              type="email"
              name="mailing-list-email"
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="form-cell">
            <button
              type="button"
              className={`btn-orange ${contactButtonEnabled ? '' : 'disabled'}`}
              disabled={!contactButtonEnabled}
              onClick={sendContactMessage}
            >
              {loading && <ClipLoader color="#fc8124" />}
              {!loading && 'Submit'}
            </button>
          </div>
        </form>
      </div>
    );
  };

  const renderThankYouMessage = () => {
    return (
      <div>
        <p>
          Thank you for subscribing. We will keep you updated as new
          diversification options become available.
        </p>
      </div>
    );
  };

  return (
    <div className="empty-wrapper">
      <h2>
        Unfortunately we could not find a suitable diversification option for
        your plot at this time
      </h2>
      {!showMessageSent && renderContactform()}
      {showMessageSent && renderThankYouMessage()}
    </div>
  );
};

export default EmptyOpportunity;
