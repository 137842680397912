import './index.scss';

type Props = {
  title: string;
  date: string;
  description: string;
  flip: boolean;
  image: string;
};

export default function TimelineEntry(props: Props) {
  return (
    <div className="timeline-entry">
      <div className={`inner ${props.flip ? 'flip' : ''}`}>
        <div
          className="pic"
          style={{ backgroundImage: `url(${props.image})` }}
        ></div>
        <div className="details">
          <div className="details-box">
            <p className="date">{props.date}</p>
            <h4>{props.title}</h4>
            <p className="info">{props.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
