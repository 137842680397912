import React from 'react';
import rotateImg from 'assets/imgs/rotate.png';
import { useMedia } from 'lib/helpers/useMedia';
import { isMobile } from 'react-device-detect';

const RotatePhone = () => {
  const isLandscape = useMedia('(orientation: landscape)');

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        zIndex: 999999,
        position: 'fixed',
        backgroundColor: '#212121',
        top: 0,
        left: 0,
        display: isMobile && isLandscape ? 'block' : 'none'
      }}
    >
      <img
        alt={'Rotate Your Phone'}
        src={rotateImg}
        style={{
          width: '100%',
          height: '100%'
        }}
      />
    </div>
  );
};

export default RotatePhone;
