import React from 'react';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import orangeArrow from 'assets/imgs/icons/orange-arrow.svg';
import { news } from 'lib/constants/routes';
import parse from 'html-react-parser';

import './ArticlePage.scss';

const ArticlePage = (props: IArticlePageProps) => {
  const { article } = props;

  return (
    <div className="article-page">
      <div className="dashboard-top-block">{article.title}</div>
      <div className="dashboard-breadcrumbs">
        <Link to={news}>Blog</Link>
        <img src={orangeArrow} alt="" />
        {article.title}
      </div>
      <div className="content-wrapper">
        <Container className="content-wrapper-container">
          <div className="content">
            <div className="top-img-block">
              <img src={article.image.url} alt="" />
            </div>
            <div className="article-content">{parse(article.content)}</div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ArticlePage;

export interface IArticlePageProps {
  article: {
    content: string;
    created_at: string;
    id: number;
    image: {
      url: string;
    };
    short_description: string;
    title: string;
  };
}
