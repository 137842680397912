import {
  LinearProgress,
  linearProgressClasses,
  LinearProgressProps,
  styled
} from '@mui/material';
import { FC } from 'react';

type Props = LinearProgressProps & {
  customColor: string;
};

const StyledLinearProgress = styled(LinearProgress)<{ customColor: string }>(
  ({ customColor }) => ({
    width: '100%',
    height: 10,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#E2E2E2'
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 10,
      backgroundColor: customColor
    }
  })
);

const ProgressBar: FC<Props> = ({ customColor, ...linearProgressProps }) => {
  return (
    <StyledLinearProgress customColor={customColor} {...linearProgressProps} />
  );
};

export default ProgressBar;
