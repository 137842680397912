import { FC } from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';

interface Props {
  videoId: string;
}

const VideoPlayer: FC<Props> = ({ videoId }) => {
  const onPlayerReady: YouTubeProps['onReady'] = event => {
    event.target.pauseVideo();
  };

  const opts: YouTubeProps['opts'] = {
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0
    }
  };

  return <YouTube videoId={videoId} opts={opts} onReady={onPlayerReady} />;
};

export default VideoPlayer;
