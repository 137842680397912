import { gql } from '@apollo/client';

const NEWS = gql`
  query news {
    news_items {
      content
      created_at
      id
      image {
        url
      }
      short_description
      title
    }
  }
`;

export { NEWS };
