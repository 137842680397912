import React, { FC } from 'react';
import { Modal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import './clearConfirmModal.scss';

interface Props {
  open: boolean;
  clearDraw: (confirmation: 'confirm' | 'cancel') => void;
}

const ClearConfirmModal: FC<Props> = ({ open, clearDraw }) => (
  <Modal
    open={open}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500
    }}
  >
    <div className="clear-confirm-modal-container">
      <h3>Are you sure you want to clear your selection?</h3>
      <div className="buttonsContainer">
        <button className={'btn-orange'} onClick={() => clearDraw('confirm')}>
          Confirm
        </button>
        <button className={'btn-orange'} onClick={() => clearDraw('cancel')}>
          Cancel
        </button>
      </div>
    </div>
  </Modal>
);

export default ClearConfirmModal;
