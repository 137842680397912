import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import {
  mapRoute,
  aboutUsRoute,
  partnersRoute,
  opportunities,
  application,
  news,
  newsRoute,
  contactUsRoute
} from 'lib/constants/routes';
import useScrollToTop from 'lib/helpers/useScrollTop';
// import ProtectedRoutes from 'lib/helpers/ProtectedRoutes';
import ReactGA from 'react-ga';

import AboutUsPage from 'components/pages/AboutUs';
// import AccountsPage from 'components/pages/Accounts';
import ApplicationPage from 'components/pages/Application';
// import ArticlePageContainer from 'components/pages/Article/ArticlePageContainer';
// import DashboardPage from 'components/pages/Dashboard';
import HomePageContainer from 'components/pages/Home/HomePageContainer';
import MapDrawPageContainer from 'components/pages/MapDraw/MapDrawPageContainer';
import NewsPageContainer from 'components/pages/News/NewsPageContainer';
import OpportunitiesPage from 'components/pages/Opportunities';
import PartnersPage from 'components/pages/Partners';
import ArticlePageContainer from './components/pages/Article/ArticlePageContainer';
import ContactUsPage from 'components/pages/ContactUs';
import { LoadingProps } from 'components/shared/LoadingModal/LoadingModal';
// import Project from 'components/pages/Project';
// import ProjectsPage from 'components/pages/Projects';
// import SignInPageContainer from 'components/pages/SignIn/SignInPageContainer';
// import DashboardWrapper from 'components/shared/DashboardWrapper';
// import ForgotPassword from 'components/pages/ForgotPassword';
// import ResetPassword from 'components/pages/ResetPassword';

export type CommonProps = {
  setLoadingCallback: React.Dispatch<React.SetStateAction<LoadingProps>>;
};

function Router({ setLoadingCallback }: CommonProps) {
  useScrollToTop();
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<HomePageContainer />} />
      <Route path={mapRoute} element={<MapDrawPageContainer />} />
      <Route path={aboutUsRoute} element={<AboutUsPage />} />
      <Route path={contactUsRoute} element={<ContactUsPage />} />
      <Route path={partnersRoute} element={<PartnersPage />} />
      {/*<Route path={signInRoute} element={<SignInPageContainer />} />*/}
      {/*<Route path={forgotPassword} element={<ForgotPassword />} />*/}
      {/*<Route path={resetPassword} element={<ResetPassword />} />*/}
      <Route
        path={opportunities}
        element={<OpportunitiesPage setLoadingCallback={setLoadingCallback} />}
      />
      <Route
        path={application}
        element={<ApplicationPage setLoadingCallback={setLoadingCallback} />}
      />
      <Route path={news} element={<NewsPageContainer />} />
      <Route path={newsRoute} element={<ArticlePageContainer />} />

      {/*<Route element={<ProtectedRoutes />}>*/}
      {/*  <Route element={<DashboardWrapper />}>*/}
      {/*    <Route path={dashboard} element={<DashboardPage />} />*/}
      {/*    <Route path={projects} element={<ProjectsPage />} />*/}
      {/*    <Route path={project} element={<Project />} />*/}
      {/*    <Route path={accounts} element={<AccountsPage />} />*/}
      {/*  </Route>*/}
      {/*</Route>*/}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default Router;
