import { gql } from '@apollo/client';

const ABOUT_US = gql`
  query aboutUsPage {
    about_us_pages {
      info_a
      info_b
    }
    team_members {
      about
      id
      image {
        url
      }
      name
      position
    }
    up_acre_timeline_entries {
      id
      date
      image {
        url
      }
      title
      description
    }
  }
`;

export { ABOUT_US };
