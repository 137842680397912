import React, { FC, useEffect } from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CloseIcon from '@mui/icons-material/Close';
import { Modal } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import './metricModal.scss';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
))(({ theme }) => ({
  borderBottom: 0,
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(270deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2)
}));

interface Props {
  open: boolean;
  selected: string | null;
  handleClose: () => void;
  scores?: {
    [key: string]: string | number;
  };
}

type AccordeonItem = {
  title: string;
  value: string;
};

const capitalize = (txt: string) => txt.charAt(0).toUpperCase() + txt.slice(1);

const MetricModal: FC<Props> = ({
  open,
  selected,
  handleClose,
  scores = {}
}) => {
  const [expanded, setExpanded] = React.useState<string | false>(
    selected as string
  );

  const accordionsContent: Array<AccordeonItem> = Object.keys(scores)
    .map(key => {
      if (!['id', 'main_description', '__typename'].includes(key)) {
        return {
          title: key.split('_').map(capitalize).join(' '),
          value: scores[key] as string
        };
      }

      return undefined;
    })
    .filter(Boolean)
    .map(s => {
      if (s?.title === 'Timescale') {
        return {
          ...s,
          title: 'Time'
        };
      }

      if (s?.title === 'Eco Planning') {
        return {
          ...s,
          title: 'Biodiversity Net Gain'
        };
      }

      if (s?.title === 'Value') {
        return {
          ...s,
          title: 'Planning Constraints'
        };
      }

      return s;
    }) as AccordeonItem[];

  useEffect(() => {
    setExpanded(selected as string);
  }, [selected]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <div className="metric-modal">
        <div className="close-icon" onClick={handleClose}>
          <CloseIcon color="inherit" />
        </div>
        <div className="collapse-container">
          <p className="metric-modal-header">{scores?.main_description}</p>
          {accordionsContent?.map(({ title, value }) => (
            <Accordion
              key={title}
              expanded={expanded === title}
              onChange={handleChange(title)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography id={title} className="accordion-title">
                  {title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <Typography>{value}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default MetricModal;
