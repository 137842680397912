import { ApolloLink, from, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

const { REACT_APP_API_URL } = process.env;

const server_uri = REACT_APP_API_URL + '/v1/graphql';

const links = {
  namedLink: () =>
    new ApolloLink((operation, forward) => {
      if (process.env.NODE_ENV === 'development') {
        operation.setContext(() => ({
          //@ts-ignore
          uri: `${server_uri}?${operation.query.definitions[0].selectionSet.selections[0].name.value}`
        }));
      }

      return forward ? forward(operation) : null;
    }),

  auth: () =>
    new ApolloLink((operation, forward) => {
      operation.setContext(() => ({
        headers: {
          ...(!operation.getContext().withoutToken && {})
        }
      }));

      return forward(operation);
    }),

  retry: () =>
    new RetryLink({
      delay: {
        initial: 2000,
        max: Infinity,
        jitter: true
      },
      attempts: {
        max: 10,
        retryIf: error => !!error
      }
    }),

  error: () =>
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        for (const { message, locations, path } of graphQLErrors) {
          // if (
          //   // extensions?.code === 'invalid-jwt' ||
          //   extensions?.category === 'authorization'
          // ) {
          //   location.href = `${location.origin}/sign-in`;
          //   localStorage.clear();
          // } else if (extensions?.category !== 'validation') {
          //   const msg = message
          //     ? `remoteValidation.${message}`
          //     : operation.operationName;
          //
          //   console.error(`errors:${msg}`);
          // }

          // eslint-disable-next-line no-console
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        }
      }

      if (networkError) {
        // eslint-disable-next-line no-console
        console.error(`[Network error]: ${networkError}`);

        if (typeof window !== 'undefined' && !window.navigator.onLine) {
          console.error('Sorry, your browser is offline.');
        } else {
          console.error('Some other network error occurred.');
        }
      }
    }),

  server: () =>
    new HttpLink({
      uri: server_uri
    })
};

export const link = from([
  links.namedLink(),
  links.auth(),
  links.error(),
  links.retry(),
  links.server()
]);
