import MapboxDraw, {
  DrawCustomMode,
  DrawCustomModeThis,
  MapMouseEvent,
  DrawLineString,
  MapTouchEvent,
  DrawPolygon
} from '@mapbox/mapbox-gl-draw';
import { GeoJSON } from 'geojson';

import LineGroup from './models/LineGroup';
import createControlPoint from './utils/createControlPoint';
import Line from './models/Line';
import LineNode from './models/LineNode';

const Constants = MapboxDraw.constants;
const Lib = MapboxDraw.lib;
const createVertex = MapboxDraw.lib.createVertex;

interface CustomPolygonDrawState {
  line: DrawLineString;
  dottedLine: DrawLineString;
  polygon: DrawPolygon;
  lastMouseOverVertexPath: number;
  currentVertexPosition: number;
  direction: 'forward' | 'backwards';
}

interface CustomPolygonDrawMode extends DrawCustomMode {
  clickAnywhere: (
    this: DrawCustomMode & DrawCustomModeThis & this,
    state: CustomPolygonDrawState,
    e: MapMouseEvent | MapTouchEvent
  ) => void;

  clickOnVertex: (
    this: DrawCustomMode & DrawCustomModeThis & this,
    state: CustomPolygonDrawState,
    e: MapMouseEvent | MapTouchEvent
  ) => void;

  clickOrTap: (
    this: DrawCustomMode & DrawCustomModeThis & this,
    state: CustomPolygonDrawState,
    e: MapMouseEvent | MapTouchEvent
  ) => void;

  closeLoop: (
    this: DrawCustomMode & DrawCustomModeThis & this,
    state: CustomPolygonDrawState,
    lineGroup: LineGroup,
    canBeClosed: boolean
  ) => void;
}

const CustomPolygonDraw: CustomPolygonDrawMode = {
  clickAnywhere: function (
    state: CustomPolygonDrawState,
    e: MapMouseEvent | MapTouchEvent
  ): void {
    const lineGroups = getLineGroups(state);

    if (lineGroups.length < 2) {
      return;
    }

    const lineGroup = lineGroups[0];
    const dottedLineGroup = lineGroups[1];
    const line = lineGroup.lines[0];
    const dottedLine = dottedLineGroup.lines[0];

    this.updateUIClasses({ mouse: Constants.cursors.ADD });

    const node1 = new LineNode([e.lngLat.lng, e.lngLat.lat]);
    line.nodes.push(node1);
    dottedLine.nodes.shift();
    dottedLine.nodes.push(node1);

    //Add coordinate to polygon too
    state.polygon.updateCoordinate(
      `0.${state.currentVertexPosition}`,
      e.lngLat.lng,
      e.lngLat.lat
    );
    state.currentVertexPosition++;

    //if first node we prepare next node to match cursor position while its moving
    if (dottedLine.nodes.length === 1) {
      const node2 = new LineNode([e.lngLat.lng, e.lngLat.lat]);
      dottedLine.nodes.push(node2);
    }

    lineGroup.refreshFeature(state.line);
    dottedLineGroup.refreshFeature(state.dottedLine);
  },

  clickOnVertex: function (
    state: CustomPolygonDrawState,
    e: MapMouseEvent | MapTouchEvent
  ): void {
    const lineGroups = getLineGroups(state);

    if (lineGroups.length < 2 || e.featureTarget.properties === null) {
      return;
    }

    const lineGroup = lineGroups[0];
    // In draw mode, if vertex is the first one, we want to close loop on it
    const isFirstVertex = e.featureTarget.properties.coord_path === 0;

    this.closeLoop(state, lineGroup, isFirstVertex);
  },

  toDisplayFeatures: function (
    this: DrawCustomModeThis & CustomPolygonDrawMode,
    state: any,
    geojson: GeoJSON,
    display: (geojson: GeoJSON) => void
  ): void {
    const castedGeojson = geojson as DrawLineString;

    if (castedGeojson === null || castedGeojson.properties === null) {
      return;
    }

    const isActiveLine = castedGeojson.properties.id === state.dottedLine.id;
    //@ts-ignore
    castedGeojson.properties.active = isActiveLine
      ? Constants.activeStates.ACTIVE
      : Constants.activeStates.INACTIVE;

    if (!isActiveLine) {
      return display(geojson);
    }

    const lineGroups = getLineGroups(state);

    if (lineGroups.length < 2) {
      return;
    }

    const lineGroup = lineGroups[0];
    const dottedLineGroup = lineGroups[1];
    const line = lineGroup.lines[0];
    const dottedLine = dottedLineGroup.lines[0];

    const lastControlPointIndex = dottedLine.nodes.length - 1;

    // Only render the line if it has at least one real coordinate
    if (dottedLine.nodes.length < 2) {
      return;
    }

    const penultNode = dottedLine.nodes[0]; //avant dernier node
    //@ts-ignore
    castedGeojson.properties.meta = Constants.meta.FEATURE;
    display(
      createVertex(
        state.dottedLine.id,
        penultNode.coords,
        `${lastControlPointIndex}`,
        false
      )
    );

    // Display first point to allow for finishing a line into a closed loop
    if (!line.closed && isLineClosable(lineGroups)) {
      const firstNode = line.nodes[0];
      const path = 0;
      display(createControlPoint(state.line.id, firstNode.coords, path, false));
    }

    display(geojson);
  },

  clickOrTap: function (
    this: MapboxDraw.DrawCustomMode<any, any> &
      MapboxDraw.DrawCustomModeThis &
      CustomPolygonDrawMode,
    state: CustomPolygonDrawState,
    e: MapMouseEvent | MapTouchEvent
  ): void {
    if (Lib.CommonSelectors.isVertex(e)) {
      return this.clickOnVertex(state, e);
    }

    this.clickAnywhere(state, e);
  },

  closeLoop: function (
    this: MapboxDraw.DrawCustomMode<any, any> &
      MapboxDraw.DrawCustomModeThis &
      CustomPolygonDrawMode,
    state: CustomPolygonDrawState,
    lineGroup: LineGroup,
    canBeClosed: boolean
  ): void {
    const line = lineGroup.lines[0];

    if (canBeClosed) {
      line.closed = true;
      //We add the complete polygon to list of features
      this.addFeature(state.polygon);
    }

    lineGroup.refreshFeature(state.line);

    return this.changeMode(Constants.modes.SIMPLE_SELECT, {
      featureIds: [state.polygon.id]
    });
  }
};

CustomPolygonDraw.onSetup = function (opts) {
  opts = opts || {};
  const featureId = opts.featureId;

  if (featureId) {
    console.log('option featureId is currently ignored on CustomPolygonDraw');
  }

  const direction = 'forward';
  const lineGroup = new LineGroup([new Line()], { portColor: '#FC8123' });
  const dottedLineGroup = new LineGroup([new Line()]);

  const line = this.newFeature(lineGroup.geojson);
  const dottedLine = this.newFeature(dottedLineGroup.geojson);

  const polygon = this.newFeature({
    type: Constants.geojsonTypes.FEATURE,
    properties: {},
    geometry: {
      type: Constants.geojsonTypes.POLYGON,
      coordinates: [[]]
    }
  });

  this.addFeature(dottedLine);
  this.addFeature(line);
  this.clearSelectedFeatures();
  Lib.doubleClickZoom.disable(this);
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  this.activateUIButton(Constants.types.LINE);
  this.setActionableState({
    trash: true,
    combineFeatures: false,
    uncombineFeatures: false
  });
  const lastMouseOverVertexPath = -1;

  const state = {
    line,
    dottedLine,
    polygon,
    lastMouseOverVertexPath,
    currentVertexPosition: 0,
    direction
  };

  return state;
};

CustomPolygonDraw.onMouseMove = function (state, e) {
  const lineGroups = getLineGroups(state);

  if (lineGroups.length < 2) {
    return;
  }

  const dottedLineGroup = lineGroups[1];
  const dottedLine = dottedLineGroup.lines[0];

  // On mousemove that is not a drag, stop extended interactions.
  this.map.dragPan.enable();

  //move next node at cursor position
  if (dottedLine.nodes.length > 0) {
    const lastNode = dottedLine.nodes[dottedLine.nodes.length - 1];
    lastNode.coords = [e.lngLat.lng, e.lngLat.lat];
    dottedLineGroup.refreshFeature(state.dottedLine);
  }

  if (Lib.CommonSelectors.isVertex(e)) {
    this.updateUIClasses({ mouse: Constants.cursors.POINTER });

    if (e.featureTarget.properties !== null) {
      state.lastMouseOverVertexPath = e.featureTarget.properties.coord_path;
    }
  } else {
    state.lastMouseOverVertexPath = -1;
  }
};

CustomPolygonDraw.onTap = CustomPolygonDraw.clickOrTap;

CustomPolygonDraw.onClick = CustomPolygonDraw.clickOrTap;

CustomPolygonDraw.onKeyUp = function (state, e) {
  if (Lib.CommonSelectors.isEnterKey(e)) {
    this.changeMode(Constants.modes.SIMPLE_SELECT, {
      featureIds: [state.line.id]
    });
  } else if (Lib.CommonSelectors.isEscapeKey(e)) {
    this.deleteFeature(state.line.id, { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT);
  }
};

CustomPolygonDraw.onMouseOut = function (state) {
  const lineGroups = getLineGroups(state);

  if (lineGroups.length < 2) {
    return;
  }

  const lineGroup = lineGroups[0];
  const line = lineGroup.lines[0];

  this.closeLoop(state, lineGroup, !line.closed && isLineClosable(lineGroups));
};

CustomPolygonDraw.onStop = function (state) {
  Lib.doubleClickZoom.enable(this);
  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.line.id) === undefined) {
    return;
  }

  //remove last added nodes
  const lineGroups = getLineGroups(state);

  if (lineGroups.length < 2) {
    return;
  }

  const dottedLineGroup = lineGroups[1];
  const dottedLine = dottedLineGroup.lines[0];
  dottedLine.removeLastNode();
  dottedLineGroup.refreshFeature(state.dottedLine);

  //On drawing finished we remove the dotted and solid line strings and replace it with polygon
  this.deleteFeature(state.line.id, { silent: true });
  this.deleteFeature(state.dottedLine.id, { silent: true });

  if (state.polygon.isValid()) {
    (this.map as any).fire(Constants.events.CREATE, {
      features: [state.polygon.toGeoJSON()]
    });
  } else {
    this.deleteFeature(state.polygon.id, { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT, {}, { silent: true });
  }
};

CustomPolygonDraw.onTrash = function (state) {
  this.deleteFeature(state.line.id, { silent: true });
  this.deleteFeature(state.dottedLine.id, { silent: true });
  this.deleteFeature(state.polygon.id, { silent: true });
  this.changeMode(Constants.modes.SIMPLE_SELECT);
};

function getLineGroups(state: CustomPolygonDrawState): LineGroup[] {
  //Ensure the state lineGroup is also modified
  let lineGroupFromProps = state.line.properties?.lineGroup;
  let dottedLineGroupFromProps = state.dottedLine.properties?.lineGroup;

  if (lineGroupFromProps == null || dottedLineGroupFromProps == null) {
    return [];
  }

  // recreate line group from itself to ensure it has the functions : Line Group from the props has no functions
  lineGroupFromProps = LineGroup.fromJSON(lineGroupFromProps);
  dottedLineGroupFromProps = LineGroup.fromJSON(dottedLineGroupFromProps);

  return [lineGroupFromProps, dottedLineGroupFromProps];
}

function isLineClosable(lineGroups: LineGroup[]) {
  if (
    lineGroups[0].lines[0].nodes.length < 2 &&
    lineGroups[1].lines[0].nodes.length < 2
  ) {
    return false;
  }

  return true;
}

export default CustomPolygonDraw;
