import { ReactComponent as TwitterIcon } from './img/iconmonstr-twitter-4.svg';
import { ReactComponent as FacebookIcon } from './img/iconmonstr-facebook-4.svg';
import { ReactComponent as InstagramIcon } from './img/iconmonstr-instagram-14.svg';
import { ReactComponent as LinkedInIcon } from './img/iconmonstr-linkedin-4.svg';

const Icon = () => {
  return null;
};

Icon.Twitter = () => {
  return <TwitterIcon />;
};

Icon.Facebook = () => {
  return <FacebookIcon />;
};

Icon.Instagram = () => {
  return <InstagramIcon />;
};

Icon.LinkedIn = () => {
  return <LinkedInIcon />;
};

export default Icon;
