import { gql } from '@apollo/client';

const MAP = gql`
  query map {
    map_pages {
      help_text
      video_url
    }
  }
`;

export { MAP };
