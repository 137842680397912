import { gql } from '@apollo/client';

const HOME = gql`
  query homePage {
    home_page_journeys(order_by: { order: asc }) {
      id
      title
      text
      icon {
        url
      }
    }
    home_page_stats {
      id
      small_text
      large_text
    }
    home_page_sections(order_by: { id: asc }) {
      title
      description
      button_text
      button_link_type
      button_link
      background_image
      image {
        url
      }
    }
  }
`;

export { HOME };
