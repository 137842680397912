import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Position } from 'geojson';

const Constants = MapboxDraw.constants;

export default function createControlPoint(
  parentId: string,
  coordinates: Position,
  path: number | string,
  selected: boolean
) {
  return {
    type: Constants.geojsonTypes.FEATURE,
    properties: {
      meta: Constants.meta.VERTEX,
      parent: parentId,
      coord_path: path,
      bezierPoint: true,
      active: selected
        ? Constants.activeStates.ACTIVE
        : Constants.activeStates.INACTIVE
    },
    geometry: {
      type: Constants.geojsonTypes.POINT,
      coordinates
    }
  };
}
