import { gql } from '@apollo/client';

const GET_OPPORTUNITIES = gql`
  query MyQuery($polygon: [Coordinate]!, $acreage: Float!) {
    AvailableOpportunities(polygon: $polygon, acreage: $acreage) {
      acreage
      agr_grade
      data {
        opportunity_name
        price
        postcode
        diy
        diy_provider_url
        diy_provider_image
        scores {
          difficulty
          eco_friendly
          land_retention
          planning_constraints
          timescale
          value
        }
        steps {
          id
          step
          instructions
        }
      }
    }
  }
`;

const CREATE_APPLICATION = gql`
  mutation CreateProject($data: ApplicationInput!) {
    CreateProject(data: $data) {
      id
    }
  }
`;

export { GET_OPPORTUNITIES, CREATE_APPLICATION };
