import { Position } from 'geojson';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

import LineNode from './LineNode';

const Constants = MapboxDraw.constants;

export default class Line {
  nodes: LineNode[];
  name: string;
  closed: boolean;
  verts: Position[];

  constructor(nodes: LineNode[] = [], closed = false, name = '') {
    this.nodes = nodes;
    this.name = name;
    this.closed = closed;
    this.verts = this.vertices;
  }

  get vertices() {
    const verts = this.nodes.map(val => val.coords);

    // Finish by close loop lastNode to first node
    if (this.closed) {
      const nextNode = this.nodes[0];
      // add last vertex at starting next node pos
      verts.push(nextNode.coords);
    }

    return verts;
  }

  get geojson() {
    const lineString = {
      type: Constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: Constants.geojsonTypes.LINE_STRING,
        coordinates: this.vertices
      }
    };

    return lineString;
  }

  removeNode(node: LineNode) {
    this.removeNodes([node]);
  }

  removeNodes(nodes: LineNode[]) {
    this.nodes = this.nodes.filter(item => !nodes.includes(item));
  }

  removeLastNode() {
    this.nodes.pop();
  }

  getNodeIndex(node: LineNode) {
    for (let i = 0; i < this.nodes.length; i++) {
      if (node === this.nodes[i]) {
        return i;
      }
    }

    return -1;
  }
}
