import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { NEWS } from 'api/strapi/news';
import { useParams } from 'react-router-dom';

import ArticlePage from './ArticlePage';

const ArticlePageContainer = () => {
  const { data } = useQuery(NEWS, {
    context: {
      withoutToken: true
    }
  });

  const { id } = useParams();

  const article = useMemo(() => {
    if (data && id) {
      return data.news_items?.find(
        (item: any) => Number(item?.id) === Number(id)
      );
    }

    return null;
  }, [data, id]);

  return article ? <ArticlePage article={article} /> : null;
};

export default ArticlePageContainer;
