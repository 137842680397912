import MapboxDraw, {
  DrawLineString,
  DrawMultiFeature
} from '@mapbox/mapbox-gl-draw';
import { Position, GeoJSON, GeoJsonProperties } from 'geojson';

import Line from './Line';
import LineNode from './LineNode';

const Constants = MapboxDraw.constants;

export default class LineGroup {
  lines: Line[];
  props: GeoJsonProperties;

  constructor(lines: Line[] = [], props: GeoJsonProperties = {}) {
    // by instanciating new Lines it will keep all its functions.
    // Because if we use lines directly it will lose all its functions in some cases.
    const newLines: Line[] = [];
    lines.forEach(line => {
      newLines.push(new Line(line.nodes, line.closed));
    });

    this.lines = newLines;
    this.props = props;
  }

  get vertices() {
    const verts: Position[][] = [];

    // If only one Line we just return the vertices of the line
    if (this.lines.length === 1) {
      return this.lines[0].vertices;
    }

    // If more than one Line we return each line vertices as an array element
    this.lines.forEach(line => {
      verts.push(line.vertices);
    });

    return verts;
  }

  get geojson(): GeoJSON {
    //Type is either a line string if only one line, or a multi line string if more than one line
    const type =
      this.lines.length === 1
        ? Constants.geojsonTypes.LINE_STRING
        : Constants.geojsonTypes.MULTI_LINE_STRING;

    const lineString = {
      type: Constants.geojsonTypes.FEATURE,
      properties: Object.assign({}, this.props, { lineGroup: this.toJSON() }),
      geometry: {
        type: type,
        coordinates: this.vertices
      }
    } as GeoJSON;

    return lineString;
  }

  removeLines(lines: Line[]) {
    this.lines = this.lines.filter(item => !lines.includes(item));
  }

  refreshFeature(
    feature: DrawLineString | DrawMultiFeature<'MultiLineString'>,
    draw: MapboxDraw.DrawCustomModeThis | null = null,
    forceRecreateFeature = false
  ) {
    if (forceRecreateFeature && draw != null) {
      // Generate new feature & delete old one
      const newFeature = draw.newFeature(this.geojson);
      draw.addFeature(newFeature);

      //@ts-ignore
      newFeature.properties = feature.properties;
      //@ts-ignore
      newFeature.properties.lineGroup = this.toJSON();

      draw.select(newFeature.id.toString());

      if (feature.id !== undefined) {
        draw.deleteFeature(feature.id.toString(), { silent: true });
      }

      return newFeature;
    } else {
      if (feature.type === Constants.geojsonTypes.LINE_STRING) {
        feature.incomingCoords(this.vertices as Position[]);
      } else {
        feature.incomingCoords(this.vertices as Position[][]);
      }

      // Just update feature
      //@ts-ignore
      feature.properties.lineGroup = this.toJSON();

      return feature;
    }
  }

  toJSON() {
    return {
      lines: this.lines.map(line => ({
        nodes: line.nodes.map(node => node.coords),
        closed: line.closed,
        name: line.name
      })),
      props: this.props
    };
  }

  static fromJSON(json: any): LineGroup {
    const lines = json.lines.map((lineData: any) => {
      const nodes = lineData.nodes.map(
        (coords: Position) => new LineNode(coords)
      );

      return new Line(nodes, lineData.closed, lineData.name);
    });

    return new LineGroup(lines, json.props);
  }
}
