import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import MapDrawPage from './MapDrawPage';

type InitialState = {
  acres: number;
  area: string | null;
  lng?: string;
  lat?: string;
};

const MapDrawPageContainer = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const initialState: InitialState = useMemo(
    () => ({
      acres: 0.0,
      area: searchParams.get('place'),
      lat: searchParams.get('center')?.split(',')[1],
      lng: searchParams.get('center')?.split(',')[0]
    }),
    []
  );

  const [drawState, setDrawState] = useState<InitialState>(initialState);

  useEffect(() => {
    setDrawState({
      acres: 0.0,
      area: searchParams.get('place'),
      lat: searchParams.get('center')?.split(',')[1],
      lng: searchParams.get('center')?.split(',')[0]
    });
  }, [location.search]);

  const setDrawStateValue = (key: 'acres' | 'lng' | 'lat', value: number) => {
    setDrawState(prev => ({
      ...prev,
      [key]: value
    }));
  };

  return (
    <MapDrawPage drawState={drawState} setDrawStateValue={setDrawStateValue} />
  );
};

export default MapDrawPageContainer;
