type PolygonPoint = {
  x: Number;
  y: Number;
};

export function getPolygon(): PolygonPoint[] {
  const strObj = localStorage.getItem('selectedArea');

  if (!strObj) {
    return [];
  }

  const res = [];

  for (let i = 0; i < Object.keys(strObj).length / 2; i++) {
    res.push({
      x: Number(JSON.parse(strObj)[`x${i}`]),
      y: Number(JSON.parse(strObj)[`y${i}`])
    });
  }

  return res;
}
