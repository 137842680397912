import { useQuery } from '@apollo/client';
import React from 'react';
import { NEWS } from 'api/strapi/news';

import NewsPage from './NewsPage';

const NewsPageContainer = () => {
  const { data } = useQuery(NEWS, {
    context: {
      withoutToken: true
    }
  });

  return data ? <NewsPage articles={data?.news_items} /> : null;
};

export default NewsPageContainer;
