import ClampLines from 'react-clamp-lines';
import fallbackImg from 'assets/imgs/fallback.png';
import { useQuery } from '@apollo/client';
import { PARTNERS_PAGE } from 'api/strapi/partners';
import './index.scss';

type Partner = {
  id: number;
  name: string;
  image: {
    url: string;
  } | null;
  specification: string;
  info: string;
  url: string;
};

const PartnersPage = () => {
  const { data } = useQuery(PARTNERS_PAGE, {
    context: {
      withoutToken: true
    }
  });

  return (
    <div className="partners-page">
      <div className="title">Partners</div>
      <p className="description">
        {data?.partners_pages?.[0]?.page_description}
      </p>
      <div className={'partners-list-container'}>
        {(data?.partners as Partner[])?.map((partnerItem, index) => (
          <div className="partner-card" key={index}>
            <div className="img-block">
              <img
                src={partnerItem?.image?.url || fallbackImg}
                alt={partnerItem.name}
              />
            </div>
            <div className="text-block">
              <div className="name">{partnerItem.name}</div>
              <div className="type">{partnerItem.specification}</div>
            </div>
            <div className="overflowed-block">
              <div className="name">{partnerItem.name}</div>
              <div className="type">{partnerItem.specification}</div>
              <ClampLines
                text={partnerItem.info || ''}
                id={`card-text ${index}`}
                lines={5}
                ellipsis="..."
                className="text"
                innerElement="div"
                buttons={false}
              />
              <a target="_blank" href={partnerItem.url} rel="noreferrer">
                <button type="button">More info</button>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartnersPage;
