import { useMatch } from 'react-router-dom';
import { application, mapRoute } from 'lib/constants/routes';
import { isChrome, isIOS, isMobile, isSafari } from 'react-device-detect';

import Footer from '../UI/Footer';
import Header from '../UI/Header';
import './MainGrid.scss';

const MainGrid = (props: any) => {
  const { children } = props;
  const applicationMatch = useMatch(application);
  const mapMatch = useMatch(mapRoute);

  const isApplicationPage =
    applicationMatch && applicationMatch.pathname === application;

  const isMapPage = mapMatch && mapMatch.pathname === mapRoute;

  const customBrowserView = isIOS && isMobile && isSafari;
  const iphoneChromeView = isIOS && isMobile && isChrome;

  return (
    <div
      className="main-grid-component"
      style={{
        height: iphoneChromeView || customBrowserView ? '100%' : '100vh'
      }}
    >
      <Header />
      <div className="header-grid-padding" />
      <div
        className={`main-grid-content ${
          isApplicationPage ? 'application' : ''
        }`}
      >
        {children}
      </div>
      {!isMapPage && <Footer />}
    </div>
  );
};

export default MainGrid;
