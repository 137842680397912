import React, { FC } from 'react';
import { Modal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import './calendar.scss';
import Calendar, { OnChangeDateCallback, ViewCallback } from 'react-calendar';

interface Props {
  open: boolean;
  date: Date;
  handleChangeTimeSlot: OnChangeDateCallback;
  handleChangeView: ViewCallback;
  handleClose: () => void;
}

function getPreviousDay(date = new Date()) {
  let daysToAdd = -1;
  const previous = new Date(date.getTime());
  const dow = date.getDay();

  switch (dow) {
    case 0:
    case 1:
    case 2:
      daysToAdd += 3;
      break;
    case 3:
    case 4:
    case 5:
      daysToAdd += 5;
      break;
    case 6:
      daysToAdd += 4;
      break;
    default:
      daysToAdd += 3;
      break;
  }

  previous.setDate(date.getDate() + daysToAdd);

  return previous;
}

const WaitModal: FC<Props> = ({
  open,
  date,
  handleChangeTimeSlot,
  handleChangeView,
  handleClose
}) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500
    }}
  >
    <div className="calendar-modal-container">
      <Calendar
        onActiveStartDateChange={handleChangeView}
        onChange={handleChangeTimeSlot}
        value={date}
        tileDisabled={({ date: calendarDate }) => {
          return (
            calendarDate < getPreviousDay() ||
            calendarDate.getDay() === 0 ||
            calendarDate.getDay() === 6
          );
        }}
      />
      <div className="submit-container">
        <div>
          <button type="button" className="btn-orange" onClick={handleClose}>
            Confirm
          </button>
          <button
            type="button"
            className="btn-orange"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </Modal>
);

export default WaitModal;
