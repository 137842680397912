import { ChangeEventHandler, useEffect, useMemo, useState } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { OnChangeDateCallback, ViewCallbackProperties } from 'react-calendar';
import { useNavigate } from 'react-router-dom';
import {
  NotificationContainer
  // @ts-ignore
} from 'react-notifications';
import axios from 'axios';
import { API_URL } from 'lib/constants/envConstants';
import { getPolygon } from 'lib/polygon';
import { getOpportunityDetails } from 'lib/opp_details';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import { CommonProps } from 'Router';

import Calendar from './Calendar';
import CustomSelect from './CustomSelect';
import CustomCheckbox from './CustomCheckbox';
import 'react-notifications/lib/notifications.css';
import './index.scss';

interface CalendarViewChangeProps {
  viewCallbackProps: ViewCallbackProperties;
  slotsMap: Map<String, Date[]>;
}

const defaultDate = () => {
  let daysToAdd = 0;
  const nd = new Date();
  const date = new Date(nd.getTime());
  const dow = nd.getDay();

  switch (dow) {
    case 0:
    case 1:
    case 2:
      daysToAdd += 3;
      break;
    case 3:
    case 4:
    case 5:
      daysToAdd += 5;
      break;
    case 6:
      daysToAdd += 4;
      break;
    default:
      daysToAdd += 3;
  }

  date.setDate(nd.getDate() + daysToAdd);

  return date;
};

const getMonthSlots = async (start: Date): Promise<Map<String, Date[]>> => {
  const dateMap: Map<String, Date[]> = new Map();
  const utcStart = new Date(
    Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())
  );

  const firstDayNextMonth = new Date(
    Date.UTC(utcStart.getFullYear(), utcStart.getMonth() + 1, 1)
  );

  const response = await axios.get(API_URL + '/availabilityTimes', {
    params: {
      startTime: toZonedTime(utcStart, 'Europe/London').toISOString(),
      endTime: toZonedTime(firstDayNextMonth, 'Europe/London').toISOString()
    }
  });

  response.data.collection.forEach((value: any) => {
    dateMap.set(
      new Date(value.date).toISOString(),
      value.availableTimes.map((timeVal: any) => new Date(timeVal.date))
    );
  });

  return dateMap;
};

const fetchSlotsMap = async ({
  viewCallbackProps,
  slotsMap
}: CalendarViewChangeProps): Promise<Map<String, Date[]>> => {
  const newSlotsMap = new Map(slotsMap);
  const newStartDate = viewCallbackProps.activeStartDate;

  if (slotsMap.has(newStartDate.toISOString())) {
    return newSlotsMap;
  }

  const newMonthMap = await getMonthSlots(newStartDate);
  newMonthMap.forEach((value, key) => {
    newSlotsMap.set(key, value);
  });

  return newSlotsMap;
};

const ApplicationPage = ({ setLoadingCallback }: CommonProps) => {
  const [availabilitySlots, setAvailabilitySlots] = useState<
    Map<String, Date[]>
  >(new Map());

  const navigate = useNavigate();
  const polygon = useMemo(() => getPolygon(), []);

  useEffect(() => {
    if (polygon.length === 0) {
      navigate('/', {
        state: {
          applicationError:
            'Your map coordinates have been lost. This may have happened if you started a search in a new tab.\nPlease start your search again.'
        }
      });
    }
  }, []);

  const [calendarDate, setCalendarDate] = useState<Date>(defaultDate());
  const [timeSlot, setTimeSlot] = useState({
    slot: '',
    properties: { date: null }
  });

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoadingCallback({
      open: loading,
      title: 'We are processing your application.',
      description:
        'Your privacy is important to us. Your land will only be shared with project partners.'
    });
  }, [loading]);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });

  const [errors, setErrors] = useState({
    email: false,
    phoneNumber: false
  });

  const [checkboxes, setCheckboxes] = useState({
    isOwner: false,
    termsAndConditions: false
  });

  const opportunityDetails = getOpportunityDetails();

  useEffect(() => {
    const appDet = localStorage.getItem('applicationDetails');

    if (appDet) {
      setPhoneNumber(JSON.parse(appDet)?.phoneNumber || '');
      setTimeSlot(JSON.parse(appDet)?.timeSlot || '');
      setCalendarDate(new Date(JSON.parse(appDet)?.date || new Date()));
    }

    getMonthSlots(calendarDate).then(slots => {
      setAvailabilitySlots(slots);
    });
  }, []);

  const [calendarOpen, setCalendarOpen] = useState(false);

  const handleChangePhoneNumber: ChangeEventHandler<HTMLInputElement> = e => {
    const phoneRegex =
      /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;

    setErrors({ ...errors, phoneNumber: !phoneRegex.test(e.target.value) });

    setPhoneNumber(e.target.value);
  };

  const handleChangeUserData: ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.name === 'email') {
      const mailRegex = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;

      setErrors({ ...errors, email: !mailRegex.test(e.target.value) });
    }

    setUserData(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleChangeTimeSlot: OnChangeDateCallback = date => {
    setCalendarDate(date);
  };

  // eslint-disable-next-line max-len
  const isValidCallBackDetails = useMemo(
    () =>
      !!phoneNumber &&
      !!calendarDate &&
      !!timeSlot &&
      timeSlot.properties.date !== null &&
      !!userData.firstName &&
      !!userData.lastName &&
      !!userData.email &&
      checkboxes.isOwner &&
      checkboxes.termsAndConditions &&
      !errors.email &&
      !errors.phoneNumber,
    [calendarDate, timeSlot, phoneNumber, errors, checkboxes]
  );

  const applyButtonDisabled = useMemo(
    () => !isValidCallBackDetails || loading,
    [isValidCallBackDetails, loading]
  );

  const utcCalendarDate = new Date(
    Date.UTC(
      calendarDate.getFullYear(),
      calendarDate.getMonth(),
      calendarDate.getDate()
    )
  );

  const mappedSlots =
    availabilitySlots
      .get(utcCalendarDate.toISOString())
      ?.map((value, index) => ({
        id: index,
        slot: formatInTimeZone(value, 'Europe/London', 'HH:mm'),
        properties: { date: value }
      })) || [];

  const handleCheck = (key: 'isOwner' | 'termsAndConditions') => {
    setCheckboxes({
      ...checkboxes,
      [key]: !checkboxes[key]
    });
  };

  useEffect(() => {
    const opportunityDetails = localStorage.getItem('opportunityDetails');

    if (!opportunityDetails) {
      navigate('/');
    }
  }, []);

  return (
    <div className="application-container">
      <NotificationContainer />
      <h1>Application</h1>
      <p>{opportunityDetails.variant}</p>
      <div className="form">
        <div className="contact-info">
          <div className="input-mobile">
            <label htmlFor="firstName">
              First Name
              <span>*</span>
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              className={'phone-input-select'}
              value={userData.firstName}
              onChange={handleChangeUserData}
            />
          </div>
          <div className="input-mobile">
            <label htmlFor="lastName">
              Last Name
              <span>*</span>
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              className={'phone-input-select'}
              value={userData.lastName}
              onChange={handleChangeUserData}
            />
          </div>
          <div className="input-mobile">
            <label htmlFor="email">
              Email
              <span>*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className={`phone-input-select ${
                errors.email ? 'has-error' : ''
              }`}
              value={userData.email}
              onChange={handleChangeUserData}
            />

            <small className="error-message">
              <span className="error-message">
                {errors.email ? 'Invalid email' : ''}
              </span>
            </small>
          </div>
          <div className="input-mobile">
            <label htmlFor="mobile">
              Telephone Number
              <span>*</span>
            </label>
            <input
              type="text"
              id="mobile"
              name="mobile"
              className={`phone-input-select  ${
                errors.phoneNumber ? 'has-error' : ''
              }`}
              value={phoneNumber}
              onChange={handleChangePhoneNumber}
            />

            <small>
              <span className="error-message">
                {errors.phoneNumber ? 'Invalid phone number' : ''}
              </span>
            </small>
          </div>
        </div>
        <h3>
          Please book a time and date so that a member of our team can call to
          discuss your land
        </h3>
        <div className="contact-info">
          <div className="input-date">
            <label htmlFor="date">
              Date
              <span>*</span>
            </label>
            <div className="input-wrapper">
              <input
                type="date"
                id="date"
                name="date"
                value={calendarDate
                  ?.toLocaleDateString('en-GB', {
                    dateStyle: 'short'
                  })
                  .split('/')
                  .reverse()
                  .join('-')}
              />
              <div className="icon-container">
                <CalendarMonthIcon
                  onClick={() => setCalendarOpen(!calendarOpen)}
                  color="inherit"
                />
              </div>
            </div>
          </div>
          <div className="input-time-slot">
            <div className="calendar-container">
              <Calendar
                open={calendarOpen}
                handleClose={() => {
                  setCalendarOpen(false);
                }}
                handleChangeView={props => {
                  fetchSlotsMap({
                    viewCallbackProps: props,
                    slotsMap: availabilitySlots
                  }).then(slots => setAvailabilitySlots(slots));
                }}
                date={calendarDate}
                handleChangeTimeSlot={handleChangeTimeSlot}
              />
            </div>
            <label htmlFor="date">
              Time slot
              <span>*</span>
            </label>
            <CustomSelect
              setSelectedValue={setTimeSlot}
              selectedValue={timeSlot.slot}
              options={mappedSlots}
            />
          </div>
        </div>
        <div className="submit-container">
          <div className="checkboxes">
            <CustomCheckbox
              name="isOwner"
              checked={checkboxes.isOwner}
              onClick={handleCheck}
              text={<span>Please confirm you are the owner of the land</span>}
            />
            <CustomCheckbox
              name="termsAndConditions"
              onClick={handleCheck}
              checked={checkboxes.termsAndConditions}
              text={
                <span>
                  Please confirm you accept our{' '}
                  <a
                    rel={'noreferrer'}
                    href={
                      'https://app.termly.io/document/terms-of-use-for-website/abd5f908-a850-4faa-af7b-6a4d55295880'
                    }
                    target={'_blank'}
                    className="link"
                  >
                    Terms & Conditions
                  </a>
                </span>
              }
            />
          </div>

          <button
            onClick={async () => {
              if (isValidCallBackDetails) {
                localStorage.setItem(
                  'applicationDetails',
                  JSON.stringify({
                    phoneNumber,
                    date: calendarDate.toLocaleDateString('en-US'),
                    timeSlot
                  })
                );

                setLoading(true);

                const formattedDate = formatInTimeZone(
                  timeSlot.properties.date as unknown as Date,
                  'Europe/London',
                  "yyyy-MM-dd'T'HH:mm:ssXXX"
                );

                const applicationData = {
                  acres: Number(localStorage.getItem('acres')),
                  opportunity: opportunityDetails.variant,
                  phoneNumber,
                  date: formattedDate,
                  polygon: JSON.stringify(polygon),
                  priceEstimate: Number(opportunityDetails.price),
                  postcode: opportunityDetails.postcode,
                  email: userData.email,
                  firstName: userData.firstName,
                  lastName: userData.lastName,
                  screenshot: localStorage.getItem('screenshot') || '',
                  area: ''
                };

                await axios
                  .post(API_URL + '/createProject', {
                    input: { data: applicationData }
                  })
                  .then(function () {
                    setLoadingCallback({
                      open: false,
                      title: '',
                      description: ''
                    });
                    localStorage.removeItem('acres');
                    localStorage.removeItem('selectedArea');
                    localStorage.removeItem('opportunityDetails');
                    localStorage.removeItem('survey');
                    localStorage.removeItem('applicationDetails');
                    localStorage.removeItem('screenshot');
                    navigate('/', {
                      state: { applicationCreated: 'yes' }
                    });
                  })
                  .catch(function (error) {
                    setLoadingCallback({
                      open: false,
                      title: '',
                      description: ''
                    });

                    if (
                      error.response &&
                      error.response.data.error === 'Could not schedule meeting'
                    ) {
                      setTimeSlot({ slot: '', properties: { date: null } });
                    }
                  });
              }
            }}
            disabled={applyButtonDisabled}
            type="button"
            className={`btn-orange${applyButtonDisabled ? ' disabled' : ''}`}
          >
            Next
          </button>
          <small>
            All information is kept with the utmost privacy and confidentiality
          </small>
        </div>
      </div>
    </div>
  );
};

export default ApplicationPage;
