import { FC } from 'react';
import './index.scss';

interface Props {}

const ErrorPage: FC<Props> = () => {
  return (
    <div className="error-page-wrapper">
      <div className="error-container">
        <h2>Oops! Something went wrong!</h2>
        <button
          onClick={() => {
            window.location.href = '/';
          }}
          className={'btn-orange'}
        >
          Go back to the Homepage
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
