import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { application } from 'lib/constants/routes';

import { ReactComponent as ArrowIcon } from './imgs/thin-arrow.svg';

import './ApplyToAll.scss';

interface Props {
  title: string;
  postcode: string;
}

const ApplyToAll: FC<Props> = ({ title, postcode }) => {
  const navigate = useNavigate();

  return (
    <>
      <button
        type="button"
        className="btn-apply-to-all"
        onClick={() => {
          localStorage.setItem(
            'opportunityDetails',
            JSON.stringify({
              variant: title,
              postcode,
              price: 0
            })
          );
          localStorage.setItem('stage', '1');
          navigate(application);
        }}
      >
        Apply for All Opportunities
        <ArrowIcon />
      </button>
    </>
  );
};

export default ApplyToAll;
