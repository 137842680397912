import './CardsSection.scss'; // Import your SCSS file for component-specific styles
import { Container, Grid, Stack } from '@mui/material';
import { FC, Fragment, useMemo } from 'react';
import { Link } from 'react-router-dom';

import fallbackImg from '../Home/imgs/mian-1.jpg'; // Assuming correct path to your image

export type Section = {
  id: number;
  title: string;
  description: string;
  image: {
    url: string;
  };
  background_image?: boolean;
  button_text?: string;
  button_link_type?: string;
  button_link?: string;
};

interface Props {
  cards?: Section[];
}

interface SectionProps {
  card: Section;
  index: number;
}

const CardWithBackgroundImage: FC<SectionProps> = ({ card }) => (
  <div className="content-container image-background">
    <Container maxWidth="xl" style={{ height: '100%' }}>
      <Grid container className="first-grid">
        <Stack
          className="text-grid"
          justifyContent="flex-end"
          style={{
            backgroundImage: `url(${card.image.url})`,
            backgroundSize: 'cover'
          }}
        >
          <div className="image-background-overlay">
            <Grid item md={5} direction="column" className="text-container">
              <CardContent {...card} />
            </Grid>
          </div>
        </Stack>
      </Grid>
    </Container>
  </div>
);

const CardWithoutBackgroundImage: FC<SectionProps> = ({ card, index }) => {
  const imageComponent = (
    <Grid
      item
      md={5}
      className={`image-grid ${index % 2 != 0 ? 'image-first-order' : null}`}
    >
      <div className="image-container">
        <img
          className="main-image"
          src={card.image.url || fallbackImg}
          alt={card.title}
        />
      </div>
    </Grid>
  );

  return (
    <div className="content-container">
      <Container maxWidth="xl" style={{ height: '100%' }}>
        <Grid container spacing={2} className="first-grid">
          {imageComponent}
          <Grid item md={7} className="text-grid">
            <div className="text-container">
              <CardContent {...card} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const CardContent: FC<Section> = ({
  description,
  title,
  button_text: buttonText,
  button_link: buttonLink,
  button_link_type: buttonLinkType
}) => {
  const paragraphs = description.split('\n');

  return (
    <>
      <h1>{title}</h1>

      {paragraphs.map((value, index) => (
        <p key={index}>{value}</p>
      ))}

      {buttonText ? (
        buttonLinkType === 'internal' ? (
          <Link to={`/${buttonLink}`}>
            <button type="button" className="btn-orange">
              {buttonText}
            </button>
          </Link>
        ) : (
          <a href={buttonLink} target="_blank" rel="noopener noreferrer">
            <button type="button" className="btn-orange">
              {buttonText}
            </button>
          </a>
        )
      ) : null}
    </>
  );
};

const ThirdWhiteBlockSection: FC<Props> = ({ cards }) => {
  const secondBlockList = useMemo(
    () =>
      cards?.map((card, index) => (
        <Fragment key={card.id}>
          {card.background_image ? (
            <CardWithBackgroundImage card={card} index={index} />
          ) : (
            <CardWithoutBackgroundImage card={card} index={index} />
          )}
        </Fragment>
      )),
    [cards]
  );

  return <div className="third-white-block">{secondBlockList}</div>;
};

export default ThirdWhiteBlockSection;
