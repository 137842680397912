import { FC } from 'react';
import { Modal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import './DefaultModal.scss';

interface ModalProps {
  open: boolean;
  modalContent: ModalContent;
  handleClose: () => void;
}

export type ModalContent = {
  title: string;
  text: string;
};

const DefaultModal: FC<ModalProps> = ({
  open,
  modalContent: { title, text },
  handleClose
}) => {
  const paragraphs = text.split('\n');

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <div className="default-modal-container">
        <h2>{title}</h2>
        {paragraphs.map((value, index) => (
          <p key={index}>{value}</p>
        ))}

        <div className="buttonsContainer">
          <button className={'btn-orange'} onClick={() => handleClose()}>
            Ok
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DefaultModal;
