import { useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ISearchResultItem } from 'api/mapBox';
import { useQuery } from '@apollo/client';
import { HOME } from 'api/strapi/home';
import {
  NotificationContainer
  // @ts-ignore
} from 'react-notifications';
import useAnalyticsEventTracker from 'lib/helpers/useAnalyticsEventTracker';
import { startConfetti, stopConfetti } from 'lib/confetti';

import searchIcon from './imgs/search.svg';
import thinArrow from './imgs/thin-arrow.svg';
import 'react-notifications/lib/notifications.css';
import './HomePage.scss';
import CardsSection, { Section } from './CardsSection';
import StatsBannner, { HomeStat, JourneyItem } from './StatsBanner';
// import videoSrc from './imgs/landing-page.mp4';
import DefaultModal from 'components/shared/DefaultModal/DefaultModal';

const HomePage = (props: IHomePageProps) => {
  const { data } = useQuery(HOME, {
    context: {
      withoutToken: true
    }
  });

  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', text: '' });
  useEffect(() => {
    // @ts-ignore
    if (location.state) {
      if (location.state?.applicationCreated === 'yes') {
        setModalContent({
          title: 'CONGRATULATIONS!',
          text: 'Your application has been submitted.\nA member of our team will be in contact.'
        });
        setOpen(true);
        startConfetti();
      } else if (location.state?.applicationError !== undefined) {
        setModalContent({
          title: 'SOMETHING WENT WRONG!',
          text: location.state?.applicationError
        });
        setOpen(true);
      }
    }
  }, []);

  const handleClose = () => {
    navigate('/');
    setOpen(false);
    stopConfetti();
  };

  const gaEventTracker = useAnalyticsEventTracker('Home');
  const whiteBlockCards = data?.home_page_sections as Array<Section>;
  const journeyItems = data?.home_page_journeys as Array<JourneyItem>;
  const stats = data?.home_page_stats as Array<HomeStat>;

  const {
    autocompleteItems,
    autocompleteItemsLoading,
    searchInputValue,
    handleInputChange,
    onSearchSubmit,
    handleSelectItem
  } = props;

  const scrollWindow = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  };

  const autocompleteList = () => {
    const items = [];

    if (autocompleteItemsLoading) {
      items.push(
        <div key="loading" className="item loading">
          Loading ...
        </div>
      );
    } else {
      autocompleteItems.forEach(item => {
        items.push(
          <div
            onClick={() => handleSelectItem(item)}
            key={item?.id}
            className="item"
          >
            {item.placeName}
          </div>
        );
      });
    }

    return (
      <div className="search-autocomplete-list">{items.map(item => item)}</div>
    );
  };

  return (
    <div className="home-page">
      <NotificationContainer />
      <DefaultModal
        handleClose={handleClose}
        open={open}
        modalContent={modalContent}
      />

      <div className="top-background-block">
        {/* <video autoPlay muted loop className="background-video">
          <source src={videoSrc} type="video/mp4" />
        </video> */}

        <div className="black-back">
          <Container>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <div />
              <div className="central-block">
                <div className="title">Make the land work for you</div>
                <div className="search-input">
                  <div className="input-inner">
                    <input
                      placeholder="Enter your postcode"
                      value={searchInputValue}
                      onFocus={() => {
                        gaEventTracker('search');
                      }}
                      onChange={e => {
                        handleInputChange(e.target.value);
                      }}
                    />
                    <img src={searchIcon} alt="" />
                  </div>
                  {autocompleteList()}
                </div>
                <button
                  onClick={onSearchSubmit}
                  className={`btn-orange ${props.disabled ? 'disabled' : ''}`}
                  type="button"
                >
                  Search
                </button>
              </div>
              <div className="explore-button">
                <button type="button" onClick={scrollWindow}>
                  <img src={thinArrow} alt="" />
                </button>
              </div>
            </Grid>
          </Container>
        </div>
      </div>

      <StatsBannner stats={stats} journeyItems={journeyItems} />
      <CardsSection cards={whiteBlockCards} />
    </div>
  );
};

export default HomePage;

export interface IHomePageProps {
  disabled: boolean;
  autocompleteItems: ISearchResultItem[];
  autocompleteItemsLoading: boolean;
  searchInputValue: string;
  // eslint-disable-next-line no-unused-vars
  handleInputChange: (searchString: string) => void;
  onSearchSubmit: () => void;
  // eslint-disable-next-line no-unused-vars
  handleSelectItem: (item: ISearchResultItem) => void;
}
