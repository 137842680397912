import { gql } from '@apollo/client';

const PARTNERS_PAGE = gql`
  query partnersPage {
    partners_pages {
      page_description
    }
    partners(order_by: [{ created_at: asc }]) {
      id
      info
      name
      specification
      image {
        url
      }
      url
    }
  }
`;

export { PARTNERS_PAGE };
