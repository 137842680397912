import React, { FC, ReactNode } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import './customCheckbox.scss';

interface Props {
  checked: boolean;
  text: ReactNode;
  onClick: (key: 'isOwner' | 'termsAndConditions') => void;
  name: 'isOwner' | 'termsAndConditions';
}

const CustomCheckbox: FC<Props> = ({ checked, text, onClick, name }) => (
  <div
    className="checkbox-container"
    onClick={() => {
      onClick(name);
    }}
  >
    <div className="checkbox">
      {checked ? <CheckIcon fontSize="inherit" color="inherit" /> : null}
    </div>
    {text}
  </div>
);

export default CustomCheckbox;
