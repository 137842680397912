import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import Router from 'Router';
// import AuthProvider from 'providers/AuthProvider';
import ErrorBoundary from 'providers/errorBoundary';
import client from 'apollo/client';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { useState } from 'react';

import MainGrid from 'components/shared/Grids/MainGrid';
import RotatePhone from 'components/shared/RotatePhone';
import 'scss/main.scss';
import LoadingModal, {
  LoadingProps
} from 'components/shared/LoadingModal/LoadingModal';

const TRACKING_ID = 'UA-243463846-1'; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

if (process.env.REACT_APP_HOTJAR_ID) {
  hotjar.initialize({
    id: Number(process.env.REACT_APP_HOTJAR_ID),
    sv: Number(process.env.REACT_APP_HOTJAR_SV)
  });
}

console.log('%cVersion: 1.1.2', 'font-size: 32px; color: green;');

function App() {
  const [loading, setLoading] = useState<LoadingProps>({
    open: false,
    title: '',
    description: ''
  });

  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <ErrorBoundary>
          {/*<AuthProvider>*/}
          <MainGrid>
            <RotatePhone />
            <LoadingModal
              open={loading.open}
              title={loading.title}
              description={loading.description}
            />
            <Router setLoadingCallback={setLoading} />
          </MainGrid>
          {/*</AuthProvider>*/}
        </ErrorBoundary>
      </ApolloProvider>
    </BrowserRouter>
  );
}

export default App;
