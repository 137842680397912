import React, { FC, Dispatch, SetStateAction, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './customSelect.scss';

interface Props {
  selectedValue: string;
  setSelectedValue: Dispatch<SetStateAction<{ slot: string; properties: any }>>;
  options: { id: number; slot: string; properties: any }[];
}

const SortBySelect: FC<Props> = ({
  selectedValue,
  setSelectedValue,
  options
}) => {
  const [opened, setOpened] = useState(false);

  const handleToggleOpenState = () => {
    setOpened(prev => !prev);
  };

  const handleSelect = (value: { slot: string; properties: any }) => {
    setOpened(false);
    setSelectedValue(value);
  };

  return (
    <div className="sort-select-container">
      <div
        onClick={handleToggleOpenState}
        className={`sort-select-input ${opened ? 'opened' : ''}`}
      >
        <span>{selectedValue || 'Please Select'}</span>
        <div className="sort-select-icon">
          <ArrowDropDownIcon color="inherit" />
        </div>
      </div>
      <div className={`sort-select-options ${opened ? 'visible' : ''}`}>
        {options.map(({ id, slot, properties }) => (
          <div
            key={id}
            onClick={() => handleSelect({ slot, properties })}
            className={`options-item ${
              slot === selectedValue ? 'selected' : ''
            }`}
          >
            {slot}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SortBySelect;
