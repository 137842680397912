import { gql } from '@apollo/client';

const GET_SEARCH_RESULTS = gql`
  query forwardGeocode($search: String!, $autocomplete: Boolean) {
    forwardGeocode(search: $search, autocomplete: $autocomplete) {
      id
      center
      placeName
      placeText
    }
  }
`;

export const IS_VALID_AREA = gql`
  query IsValidArea($polygon: [Coordinate]!) {
    IsValidArea(polygon: $polygon) {
      isValid
      isLondon
    }
  }
`;

export interface ISearchResultItem {
  id: string;
  placeName: string;
  placeText: string;
  center: [string, string];
}

export { GET_SEARCH_RESULTS };
