import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { application } from 'lib/constants/routes';
import useMediaQuery from 'lib/helpers/useMediaQuery';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Gauge } from 'react-circular-gauge';
import ProgressBar from 'lib/helpers/ProgressBar';

import MetricModal from './MetricModal';
import { ReactComponent as ArrowIcon } from './imgs/thin-arrow.svg';

import './ListItem.scss';

function detectColor(value: number | boolean): string {
  if (typeof value === 'boolean') {
    return value ? '#5EBF2E' : '#DE2525';
  }

  switch (true) {
    case value >= 0 && value <= 20:
      return '#DE2525';
    case value > 20 && value <= 50:
      return '#BF6A2E';
    case value > 50 && value <= 75:
      return '#BFA72E';
    case value > 75 && value <= 100:
      return '#9BBF2E';
    default:
      return '#5EBF2E';
  }
}

const changePaymentTypeFormat = (str: string) => {
  return str.includes(' ')
    ? str.toLowerCase().split(' ').join('_')
    : str.toLowerCase();
};

type Metrics = {
  Timescale: number;
  Difficulty: number;
  Value: number;
  'Eco Planning': number;
  'Land Retention': number;
};

const sortValues: Record<keyof Metrics, string> = {
  Timescale: 'Time',
  Difficulty: 'Difficulty',
  Value: 'Planning Constraints',
  'Eco Planning': 'Biodiversity Net Gain',
  'Land Retention': 'Land Retention'
};

interface Props {
  src: string;
  title: string;
  metrics: Metrics;
  price: number | string;
  info: string;
  postcode: string;
  scores?: {
    [key: string]: string | number;
  };
  payment_type: string;
  payment_info?: {
    [key: string]: string | number;
  };
}

const newScoreNames: { [key: string]: string } = {
  Timescale: 'Time',
  Difficulty: 'Difficulty',
  Value: 'Planning Constraints',
  'Eco Planning': 'Biodiversity Net Gain',
  'Land Retention': 'Land Retention'
};

const ListItem: FC<Props> = ({
  src,
  title,
  metrics,
  price,
  info,
  postcode,
  scores = {},
  payment_type,
  payment_info
}) => {
  const navigate = useNavigate();
  const [showDetails, setShowDetails] = useState(false);
  const [selected, setSelected] = useState<string | null>(null);

  const handleShowDetails = () => {
    setShowDetails(prev => !prev);
  };

  const paymentType = changePaymentTypeFormat(payment_type);

  const isTabletOrMobile = useMediaQuery('(max-width: 1280px)');
  const isMobile = useMediaQuery('(max-width: 900px)');

  const iconStyle = (value: number) => ({
    ...(!isMobile && {
      backgroundColor: '#F5F5F5',
      borderRadius: '100px',
      padding: '15%'
    }),
    color: detectColor(value),
    width: `${isMobile ? '1em' : '100%'}`,
    height: `${isMobile ? '1em' : '100%'}`
  });

  return (
    <>
      <MetricModal
        open={!!selected}
        selected={selected}
        handleClose={() => {
          setSelected(null);
        }}
        scores={scores}
      />
      <div
        className={`list-item-with-details ${
          showDetails ? 'details-opened' : ''
        }`}
      >
        <div className={'list-item-container'}>
          <div className="image-container">
            <div className="img" style={{ backgroundImage: `url(${src})` }}>
              {isTabletOrMobile && 0.5 > 0 ? null : (
                <button type="button" onClick={() => handleShowDetails()}>
                  <ArrowIcon className={showDetails ? 'up' : ''} />
                  &nbsp; More Info
                </button>
              )}
            </div>
          </div>
          <div className="main-content">
            <h3>{title}</h3>
            <div className="tags">
              {Object.entries(metrics).map(([key, value]) => {
                return (
                  <div
                    className="tag"
                    key={key}
                    onClick={() => setSelected(newScoreNames[key])}
                  >
                    <div className="tag-gauge">
                      {
                        //@ts-ignore
                        sortValues[key] == 'Land Retention' ? (
                          value ? (
                            <CheckCircleIcon style={iconStyle(value)} />
                          ) : (
                            <CancelIcon style={iconStyle(value)} />
                          )
                        ) : isMobile ? (
                          <ProgressBar
                            customColor={detectColor(value)}
                            value={value}
                            variant="determinate"
                          />
                        ) : (
                          <Gauge
                            value={value}
                            minValue={0}
                            maxValue={100}
                            startAngle={180}
                            endAngle={180}
                            arcColor={detectColor(value)}
                            arcWidth={0.2}
                            arcCornerRadius={0}
                            trackColor={detectColor(value) + '44'}
                            trackWidth={0.2}
                            direction="ccw"
                            valueStyle={{
                              color: detectColor(value),
                              fontWeight: 'bold'
                            }}
                          />
                        )
                      }
                    </div>
                    <div className="tag-label">
                      {
                        //@ts-ignore
                        sortValues[key]
                      }
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="price-container">
            <div className={'price-info'}>
              {typeof price === 'number' ? (
                <span>
                  <b>
                    {new Intl.NumberFormat('us-gb', {
                      style: 'currency',
                      currency: 'GBP'
                    }).format(price)}{' '}
                  </b>
                  {payment_type}*
                </span>
              ) : (
                <span className="not-available">{price}</span>
              )}
              <span>
                *Valuations are strictly estimates only, and you should use them
                on that basis
                {payment_info && payment_info?.[paymentType] ? (
                  <span>&nbsp;&mdash;&nbsp;{payment_info[paymentType]}</span>
                ) : null}
              </span>
            </div>
            <div className="apply-button-container">
              <button
                type="button"
                className="btn-orange"
                onClick={() => {
                  localStorage.setItem(
                    'opportunityDetails',
                    JSON.stringify({
                      variant: title,
                      postcode,
                      price
                    })
                  );
                  localStorage.setItem('stage', '1');
                  navigate(application);
                }}
              >
                Apply
                <ArrowIcon />
              </button>
            </div>
          </div>
          {isTabletOrMobile ? (
            <div className="more-info-btn-container">
              <button
                type="button"
                className="btn-orange"
                onClick={() => handleShowDetails()}
              >
                More Info
                <ArrowIcon className={showDetails ? 'up' : ''} />
              </button>
            </div>
          ) : null}
        </div>
        <div className={`details ${showDetails ? 'show' : ''}`}>
          <h5>More Information</h5>
          <p>{info}</p>
        </div>
      </div>
    </>
  );
};

export default ListItem;
