import {
  NotificationContainer
  // @ts-ignore
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './index.scss';
import { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { contactUs } from 'lib/apis';

const ContactUsPage = () => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [contactButtonEnabled, setContactButtonEnabled] =
    useState<boolean>(false);

  const [showMessageSent, setShowMessageSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setContactButtonEnabled(
      firstName != '' && email != '' && lastName != '' && message != ''
    );
  }, [firstName, lastName, email, message]);

  const sendContactMessage = async (): Promise<void> => {
    setContactButtonEnabled(false);
    setLoading(true);
    await contactUs({ firstName, lastName, email, telephone, message });
    setContactButtonEnabled(true);
    setLoading(false);
    setShowMessageSent(true);
  };

  const renderContactform = () => {
    return (
      <div className="form">
        <div className="contact-info">
          <div className="input-mobile">
            <label htmlFor="firstName">
              First Name
              <span>*</span>
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              className={'phone-input-select'}
              onChange={e => setFirstName(e.target.value)}
            />
          </div>
          <div className="input-mobile">
            <label htmlFor="lastName">
              Last Name
              <span>*</span>
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              className={'phone-input-select'}
              onChange={e => setLastName(e.target.value)}
            />
          </div>
          <div className="input-mobile">
            <label htmlFor="email">
              Email
              <span>*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className={`phone-input-select`}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="input-mobile">
            <label htmlFor="mobile">Telephone Number</label>
            <input
              type="text"
              id="mobile"
              name="mobile"
              className={`phone-input-select`}
              onChange={e => setTelephone(e.target.value)}
            />
          </div>
          <div className="input-mobile">
            <label htmlFor="message">
              Message
              <span>*</span>
            </label>
            <textarea
              name="message"
              rows={5}
              onChange={e => setMessage(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className="submit-container">
          <button
            type="button"
            className={`btn-orange ${contactButtonEnabled ? '' : 'disabled'}`}
            disabled={!contactButtonEnabled}
            onClick={sendContactMessage}
          >
            {loading && <ClipLoader color="#fc8124" />}
            {!loading && 'Send Message'}
          </button>
        </div>
      </div>
    );
  };

  const renderThankYouMessage = () => {
    return (
      <div className="success-message">
        <p>
          Thank you for reaching out to Up Acre! Your message has been
          successfully submitted. We appreciate your interest and will get back
          to you as soon as possible. If you have any further questions or
          concerns, feel free to contact us again
        </p>
      </div>
    );
  };

  return (
    <div className="contact-us-page">
      <NotificationContainer />
      <div className="content-container">
        <h1 className="title">Contact Us</h1>
        {!showMessageSent && (
          <p className="description">
            Connect with us to explore how Up Acre can transform underperforming
            land through our expertise in diversification strategies and
            data-driven recommendations sourced from over 20,000,000 data
            points. Let us help you unlock the full potential of your land.
          </p>
        )}
        {!showMessageSent && renderContactform()}
        {showMessageSent && renderThankYouMessage()}
      </div>
    </div>
  );
};

export default ContactUsPage;
