import { InMemoryCache } from '@apollo/client';

// function merge(
//   existing = { data: [] },
//   incoming: { data: [] },
//   args?: FieldFunctionOptions
// ) {
//   // TODO:fonts there's a variable called `fetchMore` below. It does the same thing as onlyIncoming, so use the same technique and remove `fetchMore`.
//   if (args?.variables?.onlyIncoming) {
//     return incoming;
//   }
//
//   return {
//     ...incoming,
//     data: uniqBy([...existing.data, ...incoming.data], '__ref')
//   };
// }

export const cache = new InMemoryCache({});
