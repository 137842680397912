import { API_URL } from 'lib/constants/envConstants';
import axios from 'axios';

type ContactUsDetails = {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  message: string;
};

export type NoOpApplicationInfo = {
  acres: Number;
  email: string;
  polygon: string;
  screenshot: string;
};

export async function contactUs(params: ContactUsDetails) {
  return await axios.post(API_URL + '/contact-us', {
    firstName: params.firstName,
    lastName: params.lastName,
    email: params.email,
    telephone: params.telephone,
    message: params.message
  });
}

export async function noOpportunity(params: NoOpApplicationInfo) {
  return await axios.post(API_URL + '/no-opportunity', {
    acres: params.acres,
    polygon: params.polygon,
    email: params.email,
    screenshot: params.screenshot
  });
}
