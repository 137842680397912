import React from 'react';
import { Skeleton } from '@mui/material';

const ListItemSkeleton = () => (
  <div>
    <Skeleton
      style={{
        height: 300
      }}
    />
    <Skeleton
      style={{
        height: 300
      }}
    />
    <Skeleton
      style={{
        height: 300
      }}
    />
  </div>
);

export default ListItemSkeleton;
